import React from 'react';
import PropTypes from "prop-types"

const PageHeader = (props) => {
  const { title, description } = props.texts

  return (
    <div className="flex flex-wrap justify-center text-center my-8 py-4">
      <div className="w-full lg:w-6/12 px-4">
        <h2 className="text-4xl font-semibold">{title}</h2>
        <div className="flex justify-center">
          <p className="text-lg leading-relaxed m-4 text-slate-500">
            {description}
          </p>
        </div>
      </div>
    </div> 
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

PageHeader.defaultProps = {
  title: ``,
  descriptiom: ``,
}

export default PageHeader
