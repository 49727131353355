import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/general/page-header"

export default function BlogPost({ data }) {
  console.log(data)
  const post = data.feedBlog
  const SEOTitle = `${post.title} - Blog - Indrek Vändrik`
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const readingTime = post.content.encoded.split(" ").length/200;
  const dateString = `${new Date(post.isoDate).toLocaleDateString(undefined, options)} · ${Math.ceil(readingTime)} min`
  const pageData = {
    title: post.title,
    description: dateString
  }

  const content = parseContent(post.content.encoded)
  return (
    <Layout>
      <Seo title={SEOTitle} />
      <title>{post.title}</title>
      <PageHeader texts={pageData} />
      <div className="blog-content">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Layout>
  )
}

const parseContent = (content) => {
  let s = content.split("https://medium.com/@indrekvandrik/");
  for (let i = 1; i < s.length; i++) {
    let rest = s[i].split('"');
    let url = rest[0].split("-")
    url.pop();
    rest[0] = url.join("-");
    s[i] = `/${rest.join('"')}`
  }
  return s.join("");
}

export const query = graphql`
  query($id: String!) {
    feedBlog(id: {eq: $id }) {
      link
      isoDate(formatString: "YYYY-MM-DD")
      title
      categories
      content {
        encoded
      }
    }
  }
`